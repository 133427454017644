import { Show, createEffect, Suspense, ErrorBoundary } from "solid-js";
import {
  type RouteSectionProps,
  type RouteDefinition,
  createAsync,
  A,
  useNavigate,
  useAction,
} from "@solidjs/router";
import { Title } from "@solidjs/meta";
import { getBuilderPageData } from "~/services/builder";
import { Content } from "@builder.io/sdk-solid";
import { builderComponents } from "~/utils/builder/componentList";
import {
  useErrorContext,
  useSessionContext,
  useSiteContext,
} from "~/utils/contexts";
import { PERMISSION } from "~/services/roma-api/account/types";
import { toasty } from "~/components/utility/toast";
import { APIError, checkError } from "~/services/roma-api/errors";
import { MouldingNotFound } from "~/components/product/product-page";
import { PT } from "~/utils/products";
import { NotFound } from "~/components/utility";

import { importBulkFavouritesAction } from "~/services/favourites";
import { FavouriteTypeOption } from "~/services/roma-api/favourites/types";
import { setCookie } from "~/components/header/side-panel/fav-panel/utils";

// TODO
// ? do the components need to be registered somewhere? reminder for Monday.

export const route = {
  preload: ({ params }) => {
    getBuilderPageData(`/${params.index}`);
  },
} satisfies RouteDefinition;

export default function Home(props: RouteSectionProps) {
  const data = createAsync(async () => {
    try {
      const pageData = await getBuilderPageData(props.location.pathname);
      // Overkill? just return the null value?
      // if (!pageData) {
      //   throw new APIError({
      //     statusCode: 404,
      //     code: "NOT_FOUND",
      //     message: "Page not found.",
      //     path: "builder",
      //   });
      // }
      return pageData;
    } catch (error) {
      const err = checkError(error);
      if (import.meta.env.DEV) {
        console.log("[[...index].tsx]: Error caught in createAsync: ", err);
      }
      if (err instanceof APIError && err.statusCode === 404) {
        return null;
      }
    }
  });

  const bulkFav = useAction(importBulkFavouritesAction);

  const addBulkFaves = async () => {
    try {
      const faves = [
        { SKU: "2548083", Type: PT.MOULDING as FavouriteTypeOption },
        { SKU: "2032003", Type: PT.GALLERYFRAME as FavouriteTypeOption },
      ];

      const response = await bulkFav(faves);

      console.log("RESPONSE FROM IMPORT FAVES >>> ", response);
    } catch (error) {
      console.log("ERRRRORRRR >>>> ", error);
    }
  };


  const addDummysToCookieFavs = () => {
    const dummies: [string, number][] = [
      ["2548083", 1],
      ["2032003", 0],
    ]

    setCookie(dummies);
  }

  // const { session, toggleFav, isFavourited, permission } = useSessionContext();
  // const { recentlySearched, local, recentlyViewed } = useSiteContext();
  // const { addError } = useErrorContext();
  // const navigate = useNavigate();

  return (
    <>
      <Suspense>
        <div>
          <Show when={data()} fallback={<NotFound />}>
            <Title>
              {data()?.data?.title && props.location.pathname !== "/"
                ? `${data()?.data?.title} | `
                : ""}
              Roma Moulding
            </Title>

            {/* <button class="text-orange-500 border-2 border-orange-600" onClick={addDummysToCookieFavs}>
              ADD FAVS BACK TO COOKIES
            </button> */}

            {/* <button
              onClick={async () => {
                await addBulkFaves();
              }}
              class="text-orange-500 border border-orange-500"
            >
              ADD BULK FAVOURITES
            </button> */}

            {/* <details class="border-2 border-orange-500 flex space-x-4 ">
              <summary>Session Stuff</summary>
              <div class="w-1/2 border border-orange-200 p-2">
                Session:
                <pre class="whitespace-pre text-xs">
                  {JSON.stringify(
                    session(),
                    (k, v) => {
                      if (k == "token") {
                        return (v as string).substring(0, 15) + "...";
                      }
                      return v;
                    },
                    4
                  )}
                </pre>
              </div>
            </details> */}
            <Content
              model="page"
              apiKey={import.meta.env.VITE_BUILDER_APIKEY}
              content={data()}
              customComponents={builderComponents}
            />
          </Show>
        </div>
      </Suspense>
    </>
  );
}
